.blog-card {
    .img-wrap {
        border-radius: .75rem;

        img {
            border-radius: .75rem;
        }
    }

    .post-title {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.popular-blog {
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }

    .post-title {
        display: flex;

        h5 {
            transition-duration: 400ms;
        }

        span {
            margin-top: .5rem;
            border-radius: .5rem;
            flex: 0 0 2.5rem;
            max-width: 2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            font-weight: 700;
            color: $heading;
            background-color: $warning;
            margin-right: 1rem;
            display: inline-block;
            text-align: center;
            line-height: 2.5rem;
        }
    }
}

.blog-details-wrap {
    img {
        border-radius: .75rem;
    }
}

.comment-wrapper {
    padding-bottom: 10px;
    border-bottom: 1px solid $border;

    .comment-content {
        margin-bottom: 32px;
        align-items: center;

        .comment-author {
            flex: 0 0 48px;
            width: 48px;
            max-width: 48px;
            margin-right: 1rem;
            border-radius: 50%;
            height: 48px;

            img {
                border-radius: 50%;
            }
        }

        .comment-meta {
            border-left: 1px solid $border;
            padding-left: 1rem;

            p {
                font-size: 18px;
                margin-bottom: 0.25rem;
            }

            .author {
                display: block;
                font-size: 16px;
                margin-bottom: .5rem;
            }

            a.like,
            a.reply {
                display: inline-block;
                font-weight: 700;
                color: $text;
                margin-right: .5rem;
                font-size: 14px;

                i {
                    margin-right: 0.25rem;
                }

                &:focus,
                &:hover {
                    color: $primary;
                }
            }
        }
    }

    .children {
        margin-left: 50px;

        .single-comment {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}