.footer-area {
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    border-top: 1px solid $border;

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 1;
        top: 0;
        left: 0;
        z-index: -1;
        content: "";
    }

    .footer-bg-shape {
        position: absolute;
        bottom: -1px;
        left: -1px;
        z-index: 2;
        transform: rotate(-180deg) !important;
    }

    .dark-logo {
        display: none;
    }

    .footer-widget-area {
        &.border-end {
            border-color: transparent !important;
        }
    
        @media #{$breakpoint-lg} {
            &.border-end {
                border-color: $border !important;
            }
        }

        ul {
            li {
                a {
                    color: $text;
                    margin-bottom: .75rem;
                    font-size: 1rem;
                    display: block;

                    &:hover,
                    &:focus {
                        color: $primary;
                    }
                }

                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .newsletter-form {
            .form-control {
                border-radius: .75rem 0 0 .75rem;
                border-color: $text-gray;
                max-width: 400px;

                &:focus {
                    border-color: $text-gray;
                }
            }

            button {
                border-radius: 0 .75rem .75rem 0;
                max-height: 52px;
            }
        }
    }

    .footer-social-icon>a {
        font-size: 1rem;
        color: $text;
        margin-right: .75rem;

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            color: $primary;
        }
    }

    .copywrite-text {
        p {
            color: $heading;
            font-weight: 500;

            a {
                color: $primary;
            }
        }
    }

    .footer-nav {
        li {
            line-height: 1.5;

            a {
                color: $heading;
                margin: 0 .5rem;

                @media #{$breakpoint-lg} {
                    margin: 0 .75rem;
                }

                &:hover,
                &:focus {
                    color: $primary;
                }
            }

            &:first-child {
                a {
                    margin-left: 0;
                }
            }

            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}