.register-form {
    position: relative;
    z-index: 1;

    .form-group {
        position: relative;
        z-index: 1;

        label {
            position: absolute;
            text-align: center;
            z-index: 100;
            border-radius: 50%;
            top: 0;
            right: 15px;
            cursor: pointer;
            font-size: 16px;
            margin-bottom: 0;

            span {
                font-size: 12px;
                font-weight: 700;
                line-height: 52px;
            }

            &.label-psswd {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 14px;
                font-weight: 700;
                text-transform: capitalize;
                right: 1.25rem;
                z-index: 10;
            }
        }
    }

    button {
        border-radius: .75rem;
    }
}