.fz-20 {
    font-size: 20px !important;
}

.fz-18 {
    font-size: 18px !important;
}

.fz-16 {
    font-size: 16px !important;
}

.fz-14 {
    font-size: 14px !important;
}

.fz-12 {
    font-size: 12px !important;
}

.z-index-1000 {
    z-index: 1000 !important;
}

.divider {
    margin-bottom: 69px !important;
    display: block;
    width: 100%;
    height: 1px;

    @media #{$breakpoint-lg} {
        margin-bottom: 119px !important;
    }
}

.section-hidden {
    display: none !important;
    visibility: hidden !important;
}

.form-check-input {
    border-width: 2px;
}

.btn {
    font-weight: 500;
    padding: .5rem 1rem;
    font-size: 14px;

    @media #{$breakpoint-md} {
        padding: .625rem 1.25rem;
    }

    @media #{$breakpoint-lg} {
        padding: .875rem 1.75rem;
        font-size: 1rem;
    }
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .375rem .75rem;
    font-size: 14px;

    @media #{$breakpoint-md} {
        padding: .437rem .875rem;
    }

    @media #{$breakpoint-lg} {
        padding: .5rem 1.25rem;
    }
}

.btn-link {

    &:hover,
    &:focus {
        box-shadow: none;
    }
}

.btn-minimal {
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;

    &:hover,
    &:focus {
        box-shadow: none;
        background-color: transparent;
        border-color: transparent;
    }

    &.btn-primary {
        color: $primary;
    }

    &.btn-secondary {
        color: $secondary;
    }

    &.btn-success {
        color: $success;
    }

    &.btn-warning {
        color: $warning;
    }

    &.btn-danger {
        color: $danger;
    }

    &.btn-info {
        color: $info;
    }

    &.btn-dark {
        color: $heading;
    }
}

.hover-primary {
    &:hover {
        color: $primary !important;
    }
}

.hover-secondary {
    &:hover {
        color: $secondary !important;
    }
}

.hover-success {
    &:hover {
        color: $success !important;
    }
}

.hover-warning {
    &:hover {
        color: $warning !important;
    }
}

.hover-danger {
    &:hover {
        color: $danger !important;
    }
}

.hover-info {
    &:hover {
        color: $info !important;
    }
}

.bg-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.bg-fixed {
    background-attachment: fixed;
}

.bg-overlay {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        background-color: $dark;
        opacity: 0.76;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}

.bg-gray {
    background-color: $gray !important;
}

.bg-text-gray {
    background-color: $text-gray !important;
}

textarea.form-control {
    min-height: calc(8em + .75rem + 2px);
}

.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 3rem;

    h2 {
        margin-bottom: 1rem;
        font-weight: 700;
        font-size: 1.625rem;
        letter-spacing: -1px;
        line-height: 1.2;

        @media #{$breakpoint-md} {
            font-size: 1.875rem;
        }

        @media #{$breakpoint-lg} {
            font-size: 2.125rem;
        }

        @media #{$breakpoint-xl} {
            font-size: 2.5rem;
        }
    }

    p {
        margin-bottom: 0;

        @media #{$breakpoint-lg} {
            font-size: 20px;
        }
    }

    .spinner-grow {
        flex: 0 0 2rem;
        max-width: 2rem;
    }
}

table {
    min-width: 650px;
}

.card {
    border-color: $border;
    border-radius: 1rem;

    .card-body {
        padding: 1.25rem 1.25rem;
    }
}

.card-img-top {
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
}

.border,
.border-start,
.border-end,
.border-bottom,
.border-top {
    border-color: $border !important;
}

.border-dashed {
    border-top: 2px dashed $border !important;
}

#scrollTopButton {
    border: 0 !important;
    position: fixed;
    bottom: 1.25rem;
    right: 1.25rem;
    transition: all 500ms;
    z-index: 9999;
    width: 2rem;
    height: 2rem;
    background-color: $heading;
    font-size: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5rem;
    color: $white;
    cursor: pointer;

    @media #{$breakpoint-lg} {
        bottom: 2rem;
        right: 2rem;
    }
}

.modal-backdrop {
    background-color: $dark;

    &.show {
        opacity: .85;
    }
}

.share-modal {
    .modal-dialog {
        max-width: 400px;

        .modal-content {
            background-color: $white;
            border-radius: .5rem;
            border: 2px solid $text-gray;

            .modal-body {
                padding: 1.5rem;

                button.btn-close-style {
                    padding: 0;
                    width: 2rem;
                    height: 2rem;
                    text-align: center;
                    line-height: 2rem;
                    border: 0;
                    position: absolute;
                    top: -1rem;
                    right: -1rem;
                    z-index: 10;
                }
            }
        }
    }
}

[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
    width: 100%;
}

.notification-list {
    li {
        list-style: none;
        margin-bottom: 0.375rem;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            white-space: normal;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            padding: .625rem 1rem;
            background-color: $white;
            border-radius: .5rem;

            i {
                display: inline-block;
                flex: 0 0 1.5rem;
                max-width: 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                background-color: $text-gray;
                border-radius: .375rem;
                color: $white;
                line-height: 1.5rem;
                text-align: center;
            }

            &:hover,
            &:focus {
                color: $primary;
            }
        }
    }
}