// Fonts
$fonts: 'DM Sans', sans-serif;

// Template Colors

// Primary
$primary: #0d6efd;

// Secondary
$secondary: #6c757d;

// Success
$success: #198766;

// Warning
$warning: #ffd607;

// Info
$info: #0db7f0;

// Danger
$danger: #dc4135;

// White
$white: #ffffff;

// Dark
$dark: #030205;

// Dark Secondary 
$dark-sec: #0C153B;

// Heading
$heading: #1f0757;

// Text
$text: #8480ae;

// Text Gray
$text-gray: #c2d4f8;

// Border
$border: #ebebeb;

// Gray
$gray: #efeffd;