.form-control,
.form-select {
    transition-duration: 350ms;
    height: 52px;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: $heading;
    border-radius: .75rem;
    border: 2px solid $border;

    &:focus {
        box-shadow: none;
        border-color: $primary;
    }
}

.form-select {
    background-size: 20px 14px;
}

.maps-wrap {
    position: relative;
    z-index: 1;
    border-radius: .75rem;

    iframe {
        width: 100%;
        height: 400px;
        border: none;
        border-radius: .75rem;

        @media #{$breakpoint-lg} {
            height: 600px;
        }
    }
}

textarea.form-control {
    height: auto;
}